import React, { Component, PureComponent } from 'react'
import { Redirect } from 'react-router-dom'
import Moment from 'moment';
import Tabla from '../../components/Tabla'
import store from 'store'

class Welcome extends Component {
    constructor(props) {
        super(props);

        var date = Moment().format('yyyy-MM-DD')
        this.state = {
            isLoading: false,
            tipo_documento: "*",
            estado_acuse: "*",
            fechaini: date,
            fechafin: date,
            numero_documento: "",
            nit_cliente: "",
            empresa_id: null,
            empresas: [],
            columnastabla: [
                { name: "id", sortable: false, visible: false },
                { name: "tipo_documento", text: "Tipo", sortable: true, visible: true },
                { name: "numero_documento", text: "Numero Documento", sortable: true, visible: true },
                { name: "fecha_documento", text: "Fecha Documento", sortable: true, visible: true },
                { name: "nit_cliente", text: "Identificación", sortable: true, visible: true },
                { name: "nombre_cliente", text: "Nombre Cliente", sortable: true, visible: true },
                { name: "estado_acuse_texto", text: "Estado Acuse", sortable: true, visible: true },
            ],
            itemstabla: [],
            configTabla: {
                full_count: 0,
                page: 0,
                orderby: "id",
                sort_order: "desc",
                limit: 10,
                totalPages: 0,
                isLoading: false
            }
        }
    }
    componentDidMount() {
        this.empresas()
        //this.docuEmpresa()
    }
    handleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    sumitFormConsulta = (event) => {
        event.preventDefault();
        this.setState({ configTabla: { ...this.state.configTabla, page: 0 } }, () => {
            var datos = { config: this.state.configTabla }
            this.docuEmpresa(datos)
        })

        // this.setState({ configTabla: { ...this.state.configTabla, page: 0 } }, this.docuEmpresa(datos))
    }
    empresas = () => {
        fetch(`${this.props.server}dashboardsa/empresas?orderby=nombre&sort_order=asc&limit=-1`, {
            method: 'GET',
            headers: new Headers({
                'AuthorizationSa': store.get('data').token,
                'email': store.get('data').usuario.email,
                'pin': store.get('data').pin,
            })
        }).then(res => {
            return res.json()
        }).then(data => {
            console.log(data)
            const { empresas } = data
            this.setState({
                empresas: empresas
            })
        }).catch(error => { console.error(error) })
    }

    docuEmpresa = (datosFiltro) => {
        this.setState({ isLoading: true, configTabla: { ...this.state.configTabla, isLoading: true } }, () => {
            fetch(`${this.props.server}dashboardsa/empresas_documentos?limit=${datosFiltro.config.limit}&page=${datosFiltro.config.page}&orderby=${datosFiltro.config.orderby}&sort_order=${datosFiltro.config.sort_order}&tipo=${this.state.tipo_documento}&estado=${this.state.estado_acuse}&fechai=${this.state.fechaini}&fechaf=${this.state.fechafin}&numero=${this.state.numero_documento}&nit_cliente=${this.state.nit_cliente}&empresa_id=${this.state.empresa_id}`, {
                method: 'GET',
                headers: new Headers({
                    'AuthorizationSa': store.get('data').token,
                    'email': store.get('data').usuario.email,
                    'pin': store.get('data').pin,
                })
            }).then(res => {
                return res.json()
            }).then(data => {
                console.log(data)
                const { documentos, limit, page, full_count, orderby, sort_order } = data
                const totalPages = Math.ceil(full_count / limit)
                this.setState({
                    isLoading: false,
                    itemstabla: documentos,
                    configTabla: {
                        full_count: full_count,
                        page: page,
                        orderby: orderby,
                        sort_order: sort_order,
                        limit: limit,
                        totalPages: totalPages,
                        isLoading: false
                    }
                })
            }).catch(error => { console.error(error) })
        })
    }

    render() {
        if (!store.get('loggedIn') || !store.get('data')) {
            return <Redirect to={store.get('data').origin} />
        }

        const { isLoading } = this.state;

        return (
            <div className="container-fluid" >
                <h1 className="mt-4">Documentos</h1>
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">Lista de documentos por empresa</li>
                </ol>

                <div className="col-xl">
                    <div className="my-2 text-white form-busqueda">
                        <form className="p-2" onSubmit={this.sumitFormConsulta}>
                            <div className="form-row align-items-center">
                                <div className="form-group form-group-sm col-auto">
                                    <label htmlFor="inputEmpresaId">Empresas</label>
                                    <select id="inputEmpresaId" className="form-control form-control-sm" name="empresa_id" value={this.state.empresa_i} onChange={this.handleInputChange} required>
                                        <option value="">Seleccione una</option>
                                        {this.state.empresas.map(emp => (
                                            <option key={emp.id} value={emp.id}>{emp.nombre}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group form-group-sm col-auto">
                                    <label htmlFor="inputTipoDocumento">Tipo Documento</label>
                                    <select id="inputTipoDocumento" className="form-control form-control-sm" name="tipo_documento" value={this.state.tipo_documento} onChange={this.handleInputChange}>
                                        <option value="*" >Todos</option>
                                        <option value="Invoice">Factura Electrónica</option>
                                    </select>
                                </div>
                                <div className="form-group form-group-sm col-auto">
                                    <label htmlFor="inputEstadoACuse">Estado</label>
                                    <select id="inputEstadoACuse" className="form-control form-control-sm" name="estado_acuse" value={this.state.estado_acuse} onChange={this.handleInputChange}>
                                        <option value="*" >Todos</option>
                                        <option value="a" >Aceptado</option>
                                        <option value="p" >Pendiente</option>
                                        <option value="r" >Rechazado</option>
                                    </select>
                                </div>
                                <div className="form-group form-group-sm col-auto">
                                    <label >Fecha</label>
                                    <div className="input-group input-group-sm">
                                        <input type="date" className="form-control" id="inputFechaini" placeholder="Fecha" name="fechaini" value={this.state.fechaini} onChange={this.handleInputChange} required />
                                        <div className="input-group-prepend"><span className="input-group-text">a</span></div>
                                        <input type="date" className="form-control" id="inputFechafin" placeholder="Fecha" name="fechafin" value={this.state.fechafin} onChange={this.handleInputChange} required />
                                    </div>
                                </div>
                                <div className="form-group form-group-sm col-auto">
                                    <label htmlFor="inputNumero">Numero Documento</label>
                                    <input type="text" className="form-control form-control-sm" id="inputNumero" placeholder="Numero Documento" name="numero_documento" value={this.state.numero_documento} onChange={this.handleInputChange} />
                                </div>
                                <div className="form-group form-group-sm col-auto">
                                    <label htmlFor="inputNumero">Identificacion cliente</label>
                                    <input type="text" className="form-control form-control-sm" id="inputIdentificacion" placeholder="Identificacion" name="nit_cliente" value={this.state.nit_cliente} onChange={this.handleInputChange} />
                                </div>
                                <div className="form-group form-group-sm col-auto">
                                    <label htmlFor="button">&nbsp;</label>
                                    <button type="submit" className="btn btn-primary btn-sm form-control " id="button" disabled={isLoading}>
                                        {isLoading &&
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="false"></span>}
                                                &nbsp;Consultar
                                            </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <Tabla
                        config={this.state.configTabla}
                        columnas={this.state.columnastabla}
                        documentos={this.state.itemstabla}
                        traerDatos={this.docuEmpresa} />
                </div>

            </div>
        )
    };
}
export default Welcome

class CustomizedAxisTick extends PureComponent {
    render() {
        const {
            x, y, stroke, payload,
        } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-90)">{payload.value}</text>
            </g>
        );
    }
}