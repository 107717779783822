import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import store from 'store'
import Tabla from '../../components/Tabla'
import Formulario from '../../components/Formulario'

class ProductosEmpresa extends Component {
    constructor(props) {
        super(props);

        this.state = {
            codigo: "",
            descripcion: "",
            columnastabla: [
                { name: "id", sortable: false, visible: false },
                { name: "codigo", text: "Codigo", sortable: true, visible: true },
                { name: "descripcion", text: "Descripción", sortable: true, visible: true },
                { name: "precio_unitario", text: "Precio", sortable: true, visible: true },
                {
                    name: "action", text: "", type: "action", visible: true, actions: [
                        { name: "modificar", icon: "pen", action: this.editar, color: "blue" }
                    ]
                }
            ],
            itemstabla: [],
            configTabla: {
                full_count: 0,
                page: 0,
                orderby: "id",
                sort_order: "desc",
                limit: 10,
                totalPages: 0,
                isLoading: false
            }
        }
    }
    componentDidMount() {
    }
    handleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    sumitFormConsulta = (event) => {
        event.preventDefault();
        this.setState({ configTabla: { ...this.state.configTabla, page: 0 } }, () => {
            var datos = { config: this.state.configTabla }
            this.get(datos)
        })
    }
    agregar = () => {
        this.setState({
            tituloDialogo: "Creando Producto",
            metodoDialogo: `${this.props.server}dashboardsa/empresas_productos_agregar`
        }, this.dialogoForm(0))
    }
    editar = (data) => {
        this.setState({
            tituloDialogo: "Editando Producto",
            metodoDialogo: `${this.props.server}dashboardsa/empresas_productos_editar`
        }, this.dialogoForm(data.id))
    }
    dialogoForm = (id) => {
        fetch(`${this.props.server}dashboardsa/empresas_productos_editar?id=${id}`, {
            method: 'GET',
            headers: new Headers({
                'AuthorizationSa': store.get('data').token,
                'email': store.get('data').usuario.email,
                'pin': store.get('data').pin,
            })
        }).then(res => { return res.json() })
            .then(data => {
                this.dialogo.open(data)
            }).catch(error => { console.error(error) })
    }
    onInputChangeDialog = (target, state) => {
        if (target.name === 'impuesto' || target.name === 'retencion') {
            fetch(`${this.props.server}dashboardsa/empresas_list_tarifa_${target.name}?id_tipo_impuesto=${target.value}`, {
                method: 'GET',
                headers: new Headers({
                    'AuthorizationSa': store.get('data').token,
                    'email': store.get('data').usuario.email,
                    'pin': store.get('data').pin,
                })
            }).then(res => {
                return res.json()
            }).then(data => {
                this.dialogo.setOnliOneField(data.data)
            }).catch(error => { console.error(error) })
        } else if (target.name === 'button_impuesto' || target.name === 'button_retencion') {
            console.log(state)
            const name_table = target.attributes.getNamedItem('parent').value
            const field = (target.name === 'button_impuesto'
                ? (state.data.hasOwnProperty('tarifa_impuesto') ? 'tarifa_impuesto' : (state.data.hasOwnProperty('monto_impuesto') && 'monto_impuesto'))
                : 'tarifa_retencion')
            const id = state.data[field]

            let tabla = state.data[name_table]
            if (!tabla) {
                tabla = []
            }
            let ya_existe = false
            tabla.map(t => {
                if (t.tarifa_impuesto_id === state.data[field]) {
                    ya_existe = true
                }
            })
            console.log('ya existe ' + ya_existe)
            if (!ya_existe) {
                fetch(`${this.props.server}dashboardsa/empresas_tarifa_impuesto?id=${id}`, {
                    method: 'GET',
                    headers: new Headers({
                        'AuthorizationSa': store.get('data').token,
                        'email': store.get('data').usuario.email,
                        'pin': store.get('data').pin,
                    })
                }).then(res => {
                    return res.json()
                }).then(data => {
                    console.log(data.data)
                    if (data.data.tarifa_impuesto) {
                        tabla.push(data.data.tarifa_impuesto)
                        let data2 = { [name_table]: tabla }
                        this.dialogo.setOnliOneField(data2)
                    }
                }).catch(error => { console.error(error) })
            }
            // tabla.push({[target.attributes.getNamedItem('parent').value] : {tipo:state.data.impuesto,tarifa:state.data.tarifa_impuesto}})


        }


    }
    agregarImpuesto = (e) => {
        console.log(e.target)

    }
    get = (datosFiltro) => {
        this.setState({ isLoading: true, configTabla: { ...this.state.configTabla, isLoading: true } }, () => {
            fetch(`${this.props.server}dashboardsa/empresas_productos?limit=${datosFiltro.config.limit}&page=${datosFiltro.config.page}&orderby=${datosFiltro.config.orderby}&sort_order=${datosFiltro.config.sort_order}&codigo=${this.state.codigo}&descripcion=${this.state.descripcion}`, {
                method: 'GET',
                headers: new Headers({
                    'AuthorizationSa': store.get('data').token,
                    'email': store.get('data').usuario.email,
                    'pin': store.get('data').pin,
                })
            }).then(res => {
                return res.json()
            }).then(data => {
                console.log(data)
                const { items, configTabla } = data
                this.setState({
                    isLoading: false,
                    itemstabla: items,
                    configTabla: configTabla
                })
            }).catch(error => { console.error(error) })
        })
    }
    cerrandoDialogoForm = () => {
        console.log(this.state)
        var datos = { config: this.state.configTabla }
        this.get(datos)
    }
    render() {
        const { isLoading } = this.state;
        return (
            <>
                <div className="container-fluid" >
                    <h1 className="mt-4">Productos</h1>
                    <div className="ml-auto m1-md-0 ">
                        <button type="button" className="btn btn-primary btn-sm" onClick={this.agregar}>
                            Agregar Producto&nbsp;
                            <FontAwesomeIcon icon={"plus-circle"} size="sm" />
                        </button>
                    </div>
                    <div className="my-2 text-white form-busqueda">
                        <form className="p-2" onSubmit={this.sumitFormConsulta}>
                            <div className="form-row align-items-center">
                                <div className="form-group form-group-sm col-auto">
                                    <label htmlFor="inputIdentifica">Codigo</label>
                                    <input type="text" className="form-control form-control-sm" id="inputCodigo" placeholder="Codigo" name="codigo" value={this.state.codigo} onChange={this.handleInputChange} />
                                </div>
                                <div className="form-group form-group-sm col-auto">
                                    <label htmlFor="inputRazon">Descripción</label>
                                    <input type="text" className="form-control form-control-sm" id="inputDescripcion" placeholder="Descripción" name="descripcion" value={this.state.descripcion} onChange={this.handleInputChange} />
                                </div>
                                <div className="form-group form-group-sm col-auto">
                                    <label htmlFor="button">&nbsp;</label>
                                    <button type="submit" className="btn btn-primary btn-sm form-control " id="button" disabled={isLoading}>
                                        {isLoading &&
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="false"></span>}
                                                &nbsp;Consultar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-xl">
                        <Tabla
                            config={this.state.configTabla}
                            columnas={this.state.columnastabla}
                            documentos={this.state.itemstabla}
                            traerDatos={this.get} />
                    </div>
                    <Formulario
                        modal={true}
                        title={this.state.tituloDialogo}
                        large={true}
                        ref={element => { this.dialogo = element }}
                        methodput={this.state.metodoDialogo}
                        closingDialog={this.cerrandoDialogoForm}
                        onInputChange={this.onInputChangeDialog} />
                </div>
            </>
        )
    }
}
export default ProductosEmpresa