import React, { Component } from 'react'
import store from 'store'
import Tabla from '../../components/Tabla'

class EmpresaNotificacion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columnastabla: [
                { name: "id", sortable: false, visible: false },
                { name: "nombre", text: "Empresa", sortable: true, visible: true },
                { name: "descripcion", text: "Tipo Notificación", sortable: true, visible: true },
                { name: "emails_notificacion", text: "Emails", sortable: true, visible: true },
            ],
            itemstabla: [],
            configTabla: {
                full_count: 0,
                page: 0,
                orderby: "id",
                sort_order: "desc",
                limit: 10,
                totalPages: 0,
                isLoading: false
            }
        }
    }
    componentDidMount() {
        var datos = { config: this.state.configTabla }
        this.getDcumentos(datos)
    }
    getDcumentos = (datos) => {
        console.log(datos)
        fetch(`${this.props.server}dashboardsa/empresas_notificacion?limit=${datos.config.limit}&page=${datos.config.page}&orderby=${datos.config.orderby}&sort_order=${datos.config.sort_order}`, {
            method: 'GET',
            headers: new Headers({
                'AuthorizationSa': store.get('data').token,
                'email': store.get('data').usuario.email,
                'pin': store.get('data').pin,
            })
        }).then(res => {
            return res.json()
        }).then(data => {
            console.log(data)
            const { empresas_notificacion, limit, page, full_count, orderby, sort_order } = data
            const totalPages = Math.ceil(full_count / limit)
            this.setState({
                itemstabla: empresas_notificacion,
                configTabla: {
                    full_count: full_count,
                    page: page,
                    orderby: orderby,
                    sort_order: sort_order,
                    limit: limit,
                    totalPages: totalPages,
                    isLoading: false
                }
            })
        }).catch(error => { console.error(error) })
    }
    render() {
        return (
            <div className="container-fluid">
                <h1 className="mt-4">Notificacion Empresas</h1>
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">Lista de notificaciones por empresas</li>
                </ol>
                <div className="col-xl">
                    <Tabla
                        config={this.state.configTabla}
                        columnas={this.state.columnastabla}
                        documentos={this.state.itemstabla}
                        traerDatos={this.getDcumentos} />
                </div>
            </div>
        )
    }
}
export default EmpresaNotificacion
