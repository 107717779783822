import React, { Component, PureComponent } from 'react'
import { Redirect } from 'react-router-dom'
import store from 'store'
import Formulario from '../../components/Formulario'

class GenerarDocuemto extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }
    componentDidMount() {
        this.formDoc.open()
    }
    formDocOnSubmit = (event, data) => {
        event.preventDefault()
        console.log("formDocOnSubmit")
        console.log(data)
    }
    render() {
        return (
            <>
                <div className="container-fluid" >
                    <h1 className="mt-4">Generar Documentos</h1>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#datos_documento" role="tab" aria-controls="home" aria-selected="true">Datos Documento</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Datos Emisor</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Datos Receptor</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="datos_documento" role="tabpanel" aria-labelledby="home-tab">
                            <Formulario
                                modal={false}
                                ref={element => { this.formDoc = element }}
                                onInputChange={this.onInputChangeDialog}
                                onSubmit={this.formDocOnSubmit} />
                        </div>
                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">bbb</div>
                        <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">ccc</div>
                    </div>
                </div>
            </>
        )
    }
}
export default GenerarDocuemto