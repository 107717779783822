import React, { Component } from 'react'
// import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faSortUp, faSort } from '@fortawesome/free-solid-svg-icons'

//library.add(faInfoCircle, faSortDown, faSortUp, faSort, faPen, faTrash)

class Tabla extends Component {
    constructor(props) {
        super(props);
        this.state = { ...props }
    }

    // componentWillReceiveProps() {
    //     this.setState({...this.props})
    // }

    componentDidMount() {
        //this.setState({ config: { ...this.state.config, isLoading: false } })
    }

    setPage = (event, page) => {
        event.preventDefault()
        this.setState({ config: { ...this.state.config, page } }, this.getDocumentos)
    }

    decrementPage = (event) => {
        event.preventDefault()
        this.setState({ config: { ...this.state.config, page: this.state.config.page - 1 } }, this.getDocumentos)
    }

    incrementPage = (event) => {
        event.preventDefault()
        this.setState({ config: { ...this.state.config, page: this.state.config.page + 1 } }, this.getDocumentos)
    }

    setRowsPerPage = (event) => {
        this.setState({ config: { ...this.state.config, page: 0, limit: event.target.value } }, this.getDocumentos)
    }

    orderBy = (event, col) => {
        event.preventDefault()
        if (col.sortable) {
            this.setState({
                config: {
                    ...this.state.config,
                    orderby: col.name,
                    sort_order: (this.state.config.sort_order === "asc" ? "desc" : "asc")
                }
            }, this.getDocumentos)
        }
    }

    getDocumentos = () => {
        this.setState({ config: { ...this.state.config, isLoading: true } },
            () => this.props.traerDatos(this.state)
        )
    }

    render() {
        const { columnas, documentos } = this.props || this.defaultProps
        const full_count = this.props.config.full_count || 0
        const limit = this.props.config.limit || 10
        const totalPages = this.props.config.totalPages || 0
        const page = this.props.config.page || 0
        const isLoading = this.props.config.isLoading || false
        const orderby = this.props.config.orderby || "id"
        const sort_order = this.props.config.sort_order || "desc"

        var paginas = [0, 1, 2, 3, 4]
        var rowsPerPage = [10, 50, 100]
        var pageItems = paginas.map(i => {
            var iii;
            if (totalPages <= 5) {
                if (i > totalPages - 1) {
                    return (null);
                } else {
                    iii = i
                }
            } else if (page > 2 && page < totalPages - 3) {
                iii = (i + (page - 2));
            } else if (page >= totalPages - 3) {
                iii = (i + (totalPages - 5))
            } else {
                iii = i;
            }
            return (
                <li key={iii} className={`page-item ${page === (iii) && "active"} ${isLoading && "disabled"}`}>
                    <a className="page-link" href="/#" onClick={(e) => this.setPage(e, iii)}>
                        {(iii + 1)}{page === (iii) && <span className="sr-only">(current)</span>}
                    </a>
                </li>
            )
        });
        return (
            <div className="table table-responsive">
                <div className="table table-responsive">
                    <div className="fixed-table-toolbar">
                        <div className="float-right search btn-group ">
                            <div className="form-inline">
                                <label htmlFor="sel1">Registros&nbsp;</label>
                                <select id="sel1" className="form-control form-control-sm"
                                    onChange={this.setRowsPerPage}
                                    value={limit}>
                                    {rowsPerPage.map((x, i) =>
                                        <option key={i} value={x}>{x}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <table className="table table-striped table-hover table-sm">
                        {/* <caption>Documentos</caption> */}
                        <thead>
                            <tr>{Object.keys(columnas).map((key, i) => (
                                columnas[key].visible &&
                                <th key={i} scope="col"
                                    className={`${columnas[key].sortable && "sortable"}`}
                                    onClick={(e) => this.orderBy(e, columnas[key])}>
                                    {columnas[key].text || columnas[key].name}
                                    {columnas[key].sortable && (
                                        orderby === columnas[key].name
                                            ? <FontAwesomeIcon icon={sort_order === "desc" ? faSortDown : faSortUp} size="sm" />
                                            : <FontAwesomeIcon icon={faSort} size="sm" color="gray" />
                                    )}
                                </th>
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(documentos).map((keiy, i) => (
                                < tr key={i}>
                                    {Object.keys(columnas).map((keycol,icol) => (
                                        (columnas[keycol].visible && columnas[keycol].type !== "action")
                                            ? <td key={icol}>{documentos[i][columnas[keycol].name]}</td>
                                            : (columnas[keycol].visible && columnas[keycol].type === "action")
                                            && <td key={icol}>
                                                <CustomActions actions={columnas[keycol].actions} item={documentos[i]}/>
                                            </td>
                                    ))}
                                </ tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="fixed-table-toolbar">
                        <div className="float-left search btn-group ">
                            {totalPages > 0 &&
                                `Mostrando ${((limit * (page + 1)) + 1) - limit} a ${(limit * (page + 1)) > full_count ? full_count : (limit * (page + 1))} de ${full_count} registros`
                            }
                        </div>
                        <div className="float-right btn-group ">
                            <ul className="pagination pagination-sm justify-content-end">
                                <li className={`page-item ${(page === 0 || totalPages === 0 || isLoading) && "disabled"}`}>
                                    <a className="page-link" href="/#" tabIndex="-1" onClick={this.decrementPage}>Anteriro</a>
                                </li>
                                {totalPages > 4 && page >= 3 &&
                                    <li className={`page-item`}>
                                        <span className="page-link" >...</span>
                                    </li>
                                }
                                {pageItems}
                                {totalPages > 5 && page < totalPages - 3 &&
                                    <li className={`page-item`}>
                                        <span className="page-link" >...</span>
                                    </li>
                                }
                                <li className={`page-item ${(page === (totalPages - 1) || totalPages === 0 || isLoading) && "disabled"}`}>
                                    <a className="page-link" href="/#" onClick={this.incrementPage}>Siguiente</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
Tabla.defaultProps = {
    columnas: [
        { name: "id", text: "", sortable: false, visible: true },
        { name: "uno", text: "", sortable: false, visible: true },
        { name: "dos", text: "", sortable: true, visible: true },
        {
            name: "", text: "", type: "action", actions: [
                { name: "modificar", icon: "faPen", action: "modificar", color: "blue" },
                { name: "eliminar", icon: "faTrashAlt", action: "eliminar", color: "red" }
            ]
        }
    ],
    documentos: [
       // { id: 1, uno: "hola", dos: "hola..." },
    ],
    config: {
        full_count: 0,
        page: 0,
        orderby: "id",
        sort_order: "desc",
        limit: 10,
        totalPages: 0,
        isLoading: false
    }
}
export default Tabla

class CustomActions extends Component {
    render() {
        const {
            actions, item
        } = this.props;

        return (
            <div>
                {Object.keys(actions).map((key, i) => (
                    <button key={i} type="button" className="btn btn-sm btn-primary-outline" onClick={()=>actions[key].action(item)}>&nbsp;
                        <FontAwesomeIcon icon={actions[key].icon} color={actions[key].color || ""} size="sm" />
                    </button>
                ))}
            </div>
        );
    }
}