import React, { Component } from 'react'
import { properties as serverprop } from '../Properties.js'
import store from 'store'

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            values: {
                nit: "",
                email: "",
                clave: ""
            },
            message: "",
            isSubmitting: false,
            isError: false,
            isDisabled: false
        };

    }
    handleInputChange = (event) => {
        this.setState({
            values: { ...this.state.values, [event.target.name]: event.target.value }
        });
    }

    handleStoreSession = (data) => {
        this.setState({ message: data.success })
        // store.set('loggedIn', true);
        store.set('data', { empresa: data.empresa, usuario: data.usuario, token: data.token, origin: 'login' });
        this.props.history.push(`/pin`)
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ isSubmitting: true, isDisabled: true });

        fetch(`${serverprop.server}empresa/login`, {
            method: 'POST',
            body: JSON.stringify(this.state.values),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status === 404) {
                this.setState({ isSubmitting: false, isError: true, message: res.statusText });
            } else {
                this.setState({ isSubmitting: false });
            }
            this.setState({ isDisabled: false });
            return res.json();
        }).then(data => {
            console.log('entre al data')
            console.log(data)
            this.setState({ isDisabled: false });
            !data.hasOwnProperty("error")
                ? this.handleStoreSession(data)
                : this.setState({ message: data.error, isError: true });
        }).catch(error => {
            this.setState({ isSubmitting: false, isDisabled: false, message: 'Se produjo un error', isError: true })
        });
    }

    render() {
        if (store.get('loggedIn') && store.get('data') && store.get('data').usuario) {
            this.props.history.push(`/dashboardsa`)
        } else if (!store.get('loggedIn') && store.get('data') && store.get('data').usuario) {
            this.props.history.push(`/pin`)
        } else if (store.get('loggedIn')) {
            store.remove('loggedIn');
            store.remove('data');
        }
        const form_signin = {
            "width": "100%",
            "maxWidth": "330px",
            "paddingTop": "50px",
            "paddingBottom": "50px",
            "margin": "auto",
        }
        return (
            <div className="container" >
                <div className="align-items-center">                    
                    <div className="row justify-content-center">
                        <form style={form_signin} className="form-signin" onSubmit={this.handleSubmit}>
                        <div  className="text-center"><img alt="Logo" style={{ width: "200px" }} src={process.env.PUBLIC_URL + '/logo_jaivana.png'} /></div>
                            <h1 className="h3 mb-3 font-weight-normal">Por favor, registrese</h1>

                            <label htmlFor="inputEmail" className="sr-only">NIT</label>
                            <input type="number" id="inputNit" className="form-control" placeholder="Nit" required autoFocus name="nit" value={this.state.values.nit} onChange={this.handleInputChange} />

                            <label htmlFor="inputEmail" className="sr-only">Email</label>
                            <input type="email" id="inputEmail" className="form-control" placeholder="Email" required name="email" value={this.state.values.email} onChange={this.handleInputChange} />

                            <label htmlFor="inputPassword" className="sr-only">Clave</label>
                            <input type="password" id="inputPassword" className="form-control" placeholder="Clave" required name="clave" value={this.state.values.clave} onChange={this.handleInputChange} />
                            <hr className="my-4" />
                            <div className={`message ${this.state.isError && "error"}`}>
                                {this.state.isSubmitting ? "Submitting..." : this.state.message}
                            </div>
                            <button className="btn btn-lg btn-primary btn-block" type="submit" disabled={this.state.isDisabled}>Registrarse</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login