import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import store from 'store'
import Tabla from '../../components/Tabla'
import Dialogo from '../../components/DialogoInformacion'
import { Alert } from '../../components/Alerta'

class ResolucionFacturacion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columnastabla: [
                { name: "id", sortable: false, visible: false },
                { name: "nombre", text: "Empresa", sortable: true, visible: true },
                { name: "resolutionnumber", text: "Numero", sortable: true, visible: true },
                { name: "resolutiondate", text: "Fecha", sortable: true, visible: true },
                { name: "validdateto", text: "Vence", sortable: true, visible: true },
                { name: "prefix", text: "Prefijo", sortable: true, visible: true },
                {
                    name: "action", text: "", type: "action", visible: true, actions: [
                        { name: "ver_mas", icon: "pen", action: this.verMas, color: "blue" }
                    ]
                }
            ],
            itemstabla: [],
            configTabla: {
                full_count: 0,
                page: 0,
                orderby: "nombre",
                sort_order: "desc",
                limit: 10,
                totalPages: 0,
                isLoading: false
            }
        }
    }
    componentDidMount() {
        this.eventoResumen()
    }
    dialogoVermas = (datos) => {
        this.dialogo.open(datos)
    }
    verMas = (datos) => {
        this.setState({
            tituloDialogo: "Ver mas Resumen Empresa"
        }, this.dialogoVermas(datos))
    }
    onInputChangeDialog = () => { }

    cerrandoDialogoUsuario = () => {
        this.eventoResumen()
    }
    handleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    sumitFormConsulta = (event) => {
        event.preventDefault();
        this.setState({ configTabla: { ...this.state.configTabla, page: 0 } }, () => {
            var datos = { config: this.state.configTabla }
            this.eventoResumen()
        })
    }
    eventoResumen = () => {
        var datos = { config: this.state.configTabla }
        this.getResumenEmpresas(datos)
    }
    getResumenEmpresas = (datos) => {
        console.log(datos)
        fetch(`${this.props.server}dashboardsa/empresa/resoluciones_facturacion?limit=${datos.config.limit}&page=${datos.config.page}&orderby=${datos.config.orderby}&sort_order=${datos.config.sort_order}&fechai=${this.state.fecha_inicial}&fechaf=${this.state.fecha_final}&nit_empresa=${this.state.nit_empresa}`, {
            method: 'GET',
            headers: new Headers({
                'AuthorizationSa': store.get('data').token,
                'email': store.get('data').usuario.email,
                'pin': store.get('data').pin,
            })
        }).then(res => {
            return res.json()
        }).then(data => {
            console.log(data)
            const { datos, limit, page, full_count, orderby, sort_order } = data
            const totalPages = Math.ceil(full_count / limit)
            this.setState({
                itemstabla: datos,
                configTabla: {
                    full_count: full_count,
                    page: page,
                    orderby: orderby,
                    sort_order: sort_order,
                    limit: limit,
                    totalPages: totalPages,
                    isLoading: false
                }
            })
        }).catch(error => { console.error(error) })
    }

    render() {
        const { isLoading } = this.state;
        return (
            <div className="container-fluid" >
                <h1 className="mt-4">Resoluciones de Facturacion</h1>
                <div className="my-2 text-white form-busqueda">
                    <form className="p-2" onSubmit={this.sumitFormConsulta}>
                        <div className="form-row align-items-center">
                            <div className="form-group form-group-sm col-auto">
                                <label htmlFor="inputIdentifica">Fecha Inicial</label>
                                <input
                                    type="date"
                                    className="form-control form-control-sm"
                                    id="inputFechai"
                                    placeholder="Fecha Inicial"
                                    name="fecha_inicial"
                                    value={this.state.fecha_inicial}
                                    onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group form-group-sm col-auto">
                                <label htmlFor="inputRazon">Fecha Final</label>
                                <input
                                    type="date"
                                    className="form-control form-control-sm"
                                    id="inputFechaf"
                                    placeholder="Fecha Final"
                                    name="fecha_final"
                                    value={this.state.fecha_final}
                                    onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group form-group-sm col-auto">
                                <label htmlFor="inputRazon">Nit Empresa</label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="inputNitEmpresa"
                                    placeholder="Nit Empresa"
                                    name="nit_empresa"
                                    value={this.state.nit_empresa}
                                    onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group form-group-sm col-auto">
                                <label htmlFor="button">&nbsp;</label>
                                <button type="submit" className="btn btn-primary btn-sm form-control " id="button" disabled={isLoading}>
                                    {isLoading &&
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="false"></span>}
                                    &nbsp;Consultar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-xl">
                    <Tabla
                        config={this.state.configTabla}
                        columnas={this.state.columnastabla}
                        documentos={this.state.itemstabla}
                        traerDatos={this.getResumenEmpresas} />
                </div>
                <Dialogo
                    title={this.state.tituloDialogo}
                    ref={element => { this.dialogo = element }}
                />
            </div>
        )
    }

}
export default ResolucionFacturacion
