export const Alert = (type, title, text) => {

    var e
    switch (type) {
        case 'success':
            e = elementSuccess(title, text)
            break
        case 'danger':
            e = elementDanger(title, text)
            break
        default:
    }

    var alert = elementFactory(e)

    alert.getElementsByClassName('close')[0].onclick = function () {
        document.getElementById('alerta').removeChild(alert)
    }

    document.getElementById('alerta').appendChild(alert)

    setTimeout(
        function () {
            if (document.getElementById('alerta').contains(alert)) {
                document.getElementById('alerta').removeChild(alert)
            }
        },
        10000)

}

var elementFactory = (function () {
    var dummy = document.createElement('div');

    return function (outerHtml) {
        var node;
        dummy.innerHTML = outerHtml;
        node = dummy.firstChild;
        dummy.removeChild(node);
        return node;
    }
})();

var elementSuccess = (title, text) => {
    return '<div class="toast fade show" role="alert" aria-live="assertive" aria-atomic="true" style="max-width: 250px; min-width: 250px; background-color: green;">' +
        '<div class="toast-header">' +
        '<strong class="mr-auto">' + title + '</strong>' +
        '<button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' +
        '</div>' +
        '<div class="toast-body is-valid" style="color: white;">' +
        text +
        '</div>' +
        '</div>'
}

var elementDanger = (title, text) => {
    return '<div class="toast fade show" role="alert" aria-live="assertive" aria-atomic="true" style="max-width: 250px; min-width: 250px; background-color: red;">' +
        '<div class="toast-header">' +
        '<strong class="mr-auto">' + title + '</strong>' +
        '<button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' +
        '</div>' +
        '<div class="toast-body is-invalid" style="color: white;">' +
        text +
        '</div>' +
        '</div>'
}