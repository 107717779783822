import React, { Component, PureComponent } from 'react'
import { Redirect } from 'react-router-dom'
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import store from 'store'

class Welcome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataestados: [],
            datameses: []
        }
    }
    componentDidMount() {
        this.docuEmpresa()
    }
    docuEmpresa = () => {
        fetch(`${this.props.server}dashboardsa/datoshome`, {
            method: 'GET',
            headers: new Headers({
                'AuthorizationSa': store.get('data').token,
                'email': store.get('data').usuario.email,
                'pin': store.get('data').pin,
            })
        }).then(res => {
            return res.json()
        }).then(data => {
            console.log(data)
            this.setState({ dataestados: data.docestados, datameses: data.docmes })
        }).catch(error => { console.error(error) })
    }

    render() {
        if (!store.get('loggedIn') || !store.get('data')) {
            return <Redirect to='/loginSuperAdmin' />
        }

        return (
            <div className="container-fluid" >
                <h1 className="mt-4">Inicio</h1>
                <div className="row">
                    <div className="col-xl-6">
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-chart-bar mr-1"></i>
                            Estados por empresa ultimos 30 días
                        </div>
                            <div className="card-body" style={{ width: '100%', height: '300px', backgroundColor: '#f5f5f5' }}>
                                <ResponsiveContainer>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={this.state.dataestados}
                                        margin={{
                                            top: 5, right: 30, left: 20, bottom: 5,
                                        }}
                                        barSize={20}>

                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="nombre" hide={true} height={100} tick={<CustomizedAxisTick />} />
                                        <YAxis tickCount={7} />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="pendientes" stackId="a" fill="#8884d8" >
                                            {/* <LabelList dataKey="nombre_recortado" fontSize={10} width={200} position="insideEnd" angle="-90" /> */}
                                        </Bar>
                                        <Bar dataKey="aceptadas" stackId="a" fill="#82ca9d" />
                                        <Bar dataKey="rechazadas" stackId="a" fill="#dc3545" />
                                        <Bar dataKey="docrecibidos" stackId="a" fill="#F6FF33" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-chart-bar mr-1"></i>
                            Documentos por mes
                        </div>
                            <div className="card-body" style={{ width: '100%', height: '300px', backgroundColor: '#f5f5f5' }}>
                                <ResponsiveContainer>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={this.state.datameses}
                                        margin={{
                                            top: 5, right: 30, left: 20, bottom: 5,
                                        }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="mes" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="cuantos" fill="#007bff" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">

                </div>
            </div>
        )
    };
}
export default Welcome

class CustomizedAxisTick extends PureComponent {
    render() {
        const {
            x, y, stroke, payload,
        } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-90)">{payload.value}</text>
            </g>
        );
    }
}