import React from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'
import './App.css'
import Home from './pages/Home'
import AcuseDetail from './pages/Acuse'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import LoginSuperAdmin from './pages/LoginSuperAdmin'
import Pin from './pages/Pin'
import DashboardSa from './pages/DashboardSa'

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/acuse/:acuseId" component={AcuseDetail} exact />
          <Route path="/login" component={Login} exact />
          <Route path="/dashboard" component={Dashboard} exact />
          <Route path="/loginSuperAdmin" component={LoginSuperAdmin} exact />
          <Route path="/pin" component={Pin} exact />
          <Route path="/dashboardsa" component={DashboardSa} exact />
        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
