import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'


ReactDOM.render(
  <React.StrictMode>
    <App />
    {/* <div style={{ position: "absolute", top: "56px", left: "0", width: "100%", height: "100%" }} > */}
      <div aria-live="polite" aria-atomic="true" style={{ position: "absolute", width: "100%", top: "56px",zIndex: "1040" }}>
        <div style={{ position: "absolute", top: "0", right: "15px" }} id="alerta">
        </div>
      </div>
    {/* </div> */}
  </React.StrictMode>,
  document.getElementById('root')
);
