import React, { Component } from 'react'
import store from 'store'
import { Alert } from './Alerta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Dialogo extends Component {
    constructor(props) {
        super(props);
        this.baseState = this.state
        this.state = { ...props, isLoading: false }
    }

    open = (data) => {
        this.setState({ show: true, isLoading: false, data: data }, () => {
            document.body.classList.add('modal-open')
        })
    }
    close = () => {
        this.setState({ show: false }, () => {
            document.body.classList.remove('modal-open')
            this.setState(this.baseState)
            //this.props.closingDialog()
        })
    }
    render() {
        const { title, large } = this.props
        const { show, data } = this.state
        return (
            <div>
                {(show === true)
                    ? <div>
                        <div className={`modal fade ${show && "show"}`} tabIndex="-1" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
                            <div className={`modal-dialog ${large && "modal-lg"}`}>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title" id="exampleModalLabel">{title}</h6>
                                        <button type="button" className="close" onClick={this.close} aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body" >
                                    <div><pre>{JSON.stringify(data, null, 2) }</pre></div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={this.close}>Cerrar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                    :""
                }
            </div>
        )
    }
}
export default Dialogo