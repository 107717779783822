import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Moment from 'moment';
import { properties as serverprop } from '../Properties.js'
import fetch from '../utils/FetchInterceptor'
import styles from '../simple-sidebar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faSortDown, faSortUp, faFilePdf, faFileCode } from '@fortawesome/free-solid-svg-icons'
import store from 'store'
//import { map } from 'jquery'

class Documentos extends Component {
    constructor(props) {
        super(props);
        var date = Moment().format('yyyy-MM-DD')
        this.state = {
            isLoading: false,
            documentos: [],
            limit: 10,
            page: 0,
            orderby: "id",
            sort_order: "asc",
            totalPages: 0,
            rowsPerPage: [10, 50, 100],
            tipo_documento: "*",
            estado_acuse: "*",
            fechaini: date,
            fechafin: date,
            numero_documento: "",
            nit_cliente: "",
            isVerDetalle: false
        };
    }
    componentDidMount() {
        this.getDocumentos()
    }
    sumitFormConsulta = (event) => {
        event.preventDefault();
        this.setState({ page: 0 }, this.getDocumentos)
    }
    getDocumentos = () => {
        if (!store.get('loggedIn')) {
            this.props.history.push(`/login`)
        }
        this.setState({ isLoading: true })
        fetch(`${serverprop.server}dashboard/documentos?limit=${this.state.limit}&page=${this.state.page}&tipo=${this.state.tipo_documento}&estado=${this.state.estado_acuse}&fechai=${this.state.fechaini}&fechaf=${this.state.fechafin}&numero=${this.state.numero_documento}&nit_cliente=${this.state.nit_cliente}&orderby=${this.state.orderby}&sort_order=${this.state.sort_order}`, {
            method: 'GET',
            headers: new Headers({
                'Authorization': store.get('data').token,
                'email': store.get('data').usuario.email
            })
        }).then(res => {
            this.setState({ isLoading: false })
            //console.log(res)
            return res.json()
        }).then(data => {
            //console.log(data)
            const { documentos, limit, page, full_count, orderby, sort_order } = data
            const totalPages = Math.ceil(full_count / limit)
            this.setState({
                documentos: documentos,
                limit: limit,
                page: page,
                orderby: orderby,
                sort_order: sort_order,
                totalPages
            })
        }).catch(error => {
            console.error(error)
        })
    }

    handleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    setPage = (event, page) => {
        event.preventDefault();
        this.setState({ page }, this.getDocumentos);
    }

    decrementPage = (event) => {
        event.preventDefault();
        this.setState({ page: this.state.page - 1 }, this.getDocumentos);
    }

    incrementPage = (event) => {
        event.preventDefault();
        this.setState({ page: this.state.page + 1 }, this.getDocumentos);
        //console.log(this.state.page)
    }

    setRowsPerPage = (event) => {
        this.setState({ limit: event.target.value }, this.getDocumentos);
    }

    orderBy = (event, orderby) => {
        event.preventDefault();
        this.setState({
            orderby,
            sort_order: (this.state.sort_order === "asc" ? "desc" : "asc"),
        }, this.getDocumentos);
    }

    verPdf = (numero_documento, acuseId) => {
        this.btn_pdf.setAttribute("disabled", "disabled");
        fetch(`${serverprop.server}dashboard/mostrar_pdf/${acuseId}`, {
            method: 'GET',
            headers: new Headers({
                'Authorization': store.get('data').token,
                'email': store.get('data').usuario.email
            })
        }).then(res => {
            return res.blob();
        }).then(blob => {
            const href = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.download = `${numero_documento}.pdf`;
            a.href = href;
            a.click();
            a.href = '';
        }).catch(err => console.error(err))
    }

    verXml = (numero_documento, acuseId) => {
        this.btn_xml.setAttribute("disabled", "disabled");
        fetch(`${serverprop.server}dashboard/mostrar_xml/${acuseId}`, {
            method: 'GET',
            headers: new Headers({
                'Authorization': store.get('data').token,
                'email': store.get('data').usuario.email
            })
        }).then(res => {
            return res.blob();
        }).then(blob => {
            const href = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.download = `${numero_documento}.xml`;
            a.href = href;
            a.click();
            a.href = '';
        }).catch(err => console.error(err))
    }

    verDetalle = (data) => {
        var modal = (
            <div>
                <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Detalle del documento {data.numero_documento}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.cerrarDialogo}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col">
                                        <h6>Cliente</h6>
                                        <p>{data.nit_cliente} {data.nombre_cliente}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-2">
                                        <h6>Numero</h6>
                                        <p>{data.numero_documento}</p>
                                    </div>
                                    <div className="col-2">
                                        <h6>Fecha</h6>
                                        <p>{data.fecha_documento}</p>
                                    </div>
                                    <div className="col-2">
                                        <h6>Estado</h6>
                                        <p>{data.estado_acuse_texto}</p>
                                    </div>
                                    <div className="col-4">
                                        <h6>Fecha Acuse</h6>
                                        <p>{data.fecha_acuse}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col">
                                        <h6>Detalle Acuse</h6>
                                        <p>{data.observacion_acuse}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <button
                                        ref={btn_pdf => { this.btn_pdf = btn_pdf }}
                                        className="btn btn-link"
                                        onClick={() => this.verPdf(data.numero_documento, data.token)}>
                                        <FontAwesomeIcon icon={faFilePdf} color="red" />&nbsp;
                                            <u>{data.numero_documento}.pdf</u>
                                    </button>

                                    <button
                                        ref={btn_xml => { this.btn_xml = btn_xml }}
                                        type="button"
                                        className="btn btn-link"
                                        onClick={() => this.verXml(data.numero_documento, data.token)}>
                                        <FontAwesomeIcon icon={faFileCode} color="orange" />&nbsp;
                                            <u>{data.numero_documento}.xml</u>
                                    </button>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.cerrarDialogo}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"></div>
            </div>
        )
        document.body.classList.add('modal-open');
        this.setState({ modal, isVerDetalle: true })

    }

    cerrarDialogo = () => {
        document.body.classList.remove('modal-open');
        this.setState({ isVerDetalle: false })
    }

    render() {
        if (!store.get('loggedIn')) {
            return <Redirect to='/login' />
        }
        const { documentos, limit, totalPages, page, rowsPerPage, isLoading, orderby, sort_order, isVerDetalle, modal } = this.state;
        var paginas = [0, 1, 2, 3, 4];

        var pageItems = paginas.map(i => {
            var iii;
            if (totalPages <= 5) {
                if (i > totalPages - 1) {
                    return (null);
                } else {
                    iii = i
                }
            } else if (page > 2 && page < totalPages - 3) {
                iii = (i + (page - 2));
            } else if (page >= totalPages - 3) {
                iii = (i + (totalPages - 5))
            } else {
                iii = i;
            }
            return (
                <li key={iii} className={`page-item ${page === (iii) && "active"} ${isLoading && "disabled"}`}>
                    <a className="page-link" href="/#" onClick={(e) => this.setPage(e, iii)}>
                        {(iii + 1)}{page === (iii) && <span className="sr-only">(current)</span>}
                    </a>
                </li>
            )
        });




        return (
            <div>
                <div className="my-2 text-white form-busqueda">
                    <form className="p-2">
                        <div className="form-row align-items-center">
                            <div className="form-group form-group-sm col-auto">
                                <label htmlFor="inputTipoDocumento">Tipo Documento</label>
                                <select id="inputTipoDocumento" className="form-control form-control-sm" name="tipo_documento" value={this.state.tipo_documento} onChange={this.handleInputChange}>
                                    <option value="*" >Todos</option>
                                    <option value="Invoice">Factura Electrónica</option>
                                </select>
                            </div>
                            <div className="form-group form-group-sm col-auto">
                                <label htmlFor="inputEstadoACuse">Estado</label>
                                <select id="inputEstadoACuse" className="form-control form-control-sm" name="estado_acuse" value={this.state.estado_acuse} onChange={this.handleInputChange}>
                                    <option value="*" >Todos</option>
                                    <option value="a" >Aceptado</option>
                                    <option value="p" >Pendiente</option>
                                    <option value="r" >Rechazado</option>
                                </select>
                            </div>
                            <div className="form-group form-group-sm col-auto">
                                <label >Fecha</label>
                                <div className="input-group input-group-sm">
                                    <input type="date" className="form-control" id="inputFechaini" placeholder="Fecha" name="fechaini" value={this.state.fechaini} onChange={this.handleInputChange} required />
                                    <div className="input-group-prepend"><span className="input-group-text">a</span></div>
                                    <input type="date" className="form-control" id="inputFechafin" placeholder="Fecha" name="fechafin" value={this.state.fechafin} onChange={this.handleInputChange} required />
                                </div>
                            </div>
                            <div className="form-group form-group-sm col-auto">
                                <label htmlFor="inputNumero">Numero Documento</label>
                                <input type="text" className="form-control form-control-sm" id="inputNumero" placeholder="Numero Documento" name="numero_documento" value={this.state.numero_documento} onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group form-group-sm col-auto">
                                <label htmlFor="inputNumero">Identificacion cliente</label>
                                <input type="text" className="form-control form-control-sm" id="inputIdentificacion" placeholder="Identificacion" name="nit_cliente" value={this.state.nit_cliente} onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group form-group-sm col-auto">
                                <label htmlFor="button">&nbsp;</label>
                                <button type="submit" className="btn btn-primary btn-sm form-control " id="button" onClick={this.sumitFormConsulta} disabled={isLoading}>
                                    {this.state.isLoading &&
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="false"></span>}
                                    &nbsp;Consultar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="table-responsive">
                    <div className="fixed-table-toolbar">
                        <div className="float-right search btn-group ">
                            <div className="form-inline">
                                <label htmlFor="sel1">Registros&nbsp;</label>
                                <select className="form-control form-control-sm" id="sel1" onChange={this.setRowsPerPage} value={limit}>
                                    {rowsPerPage.map((x, i) =>
                                        <option key={i} value={x}>{x}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <table className="table table-striped table-hover table-sm">
                        {/* <caption>Documentos</caption> */}
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col " className="sortable" onClick={(e) => this.orderBy(e, "tipo_documento")}>Tipo {orderby === "tipo_documento" && <FontAwesomeIcon icon={sort_order === "desc" ? faSortDown : faSortUp} size="sm" />}</th>
                                <th scope="col " className="sortable" onClick={(e) => this.orderBy(e, "numero_documento")}>Numero {orderby === "numero_documento" && <FontAwesomeIcon icon={sort_order === "desc" ? faSortDown : faSortUp} size="sm" />}</th>
                                <th scope="col " className="sortable" onClick={(e) => this.orderBy(e, "fecha_documento")}>Fecha {orderby === "fecha_documento" && <FontAwesomeIcon icon={sort_order === "desc" ? faSortDown : faSortUp} size="sm" />}</th>
                                <th scope="col " className="sortable" onClick={(e) => this.orderBy(e, "nit_cliente")}>Identificación {orderby === "nit_cliente" && <FontAwesomeIcon icon={sort_order === "desc" ? faSortDown : faSortUp} size="sm" />}</th>
                                <th scope="col " className="sortable" onClick={(e) => this.orderBy(e, "nombre_cliente")}>Cliente {orderby === "nombre_cliente" && <FontAwesomeIcon icon={sort_order === "desc" ? faSortDown : faSortUp} size="sm" />}</th>
                                <th scope="col " className="sortable" onClick={(e) => this.orderBy(e, "estado_acuse_texto")}>Estado {orderby === "estado_acuse_texto" && <FontAwesomeIcon icon={sort_order === "desc" ? faSortDown : faSortUp} size="sm" />}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {documentos.map(docu =>
                                (
                                    < tr key={docu.id}>
                                        <th scope="row">{docu.fila}</th>
                                        <td>{docu.tipo_documento}</td>
                                        <td>{docu.numero_documento}</td>
                                        <td>{docu.fecha_documento}</td>
                                        <td>{docu.nit_cliente}</td>
                                        <td>{docu.nombre_cliente}</td>
                                        <td title={docu.observacion_acuse} data-toggle="tooltip" data-placement="top">
                                            {docu.estado_acuse_texto}&nbsp;<FontAwesomeIcon icon={faInfoCircle} size="sm" className="info-doc" onClick={() => this.verDetalle(docu)} />
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation">
                        <ul className="pagination justify-content-end">
                            <li className={`page-item ${(page === 0 || totalPages === 0 || isLoading) && "disabled"}`}>
                                <a className="page-link" href="/#" tabIndex="-1" onClick={this.decrementPage}>Previous</a>
                            </li>
                            {totalPages > 4 && page >= 3 &&
                                <li className={`page-item`}>
                                    <span className="page-link" >...</span>
                                </li>
                            }
                            {pageItems}
                            {totalPages > 5 && page < totalPages - 3 &&
                                <li className={`page-item`}>
                                    <span className="page-link" >...</span>
                                </li>
                            }
                            <li className={`page-item ${(page === (totalPages - 1) || totalPages === 0 || isLoading) && "disabled"}`}>
                                <a className="page-link" href="/#" onClick={this.incrementPage}>Next</a>
                            </li>
                        </ul>
                    </nav>
                </div>
                {isVerDetalle && modal}
            </div >
        )
    }
}
export default Documentos