import React, { Component } from 'react'
import store from 'store'
import { Alert } from './Alerta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Form extends Component {
    constructor(props) {
        super(props);
        this.baseState = this.state
        this.state = { ...props, isLoading: false }
    }

    handleInputChange = (event) => {
        console.log(event.target)
        if (this.state.data.fields[event.target.name]) {
            this.setState({
                data: {
                    ...this.state.data,
                    data: {
                        ...this.state.data.data,
                        [event.target.name]: event.target.type === 'checkbox'
                            ? event.target.checked
                            : this.state.data.fields[event.target.name].type === 'long'
                                ? parseInt(event.target.value, 10)
                                : this.state.data.fields[event.target.name].type === 'array'
                                    ? Array.from(event.target.selectedOptions, option => option.value)
                                    : this.state.data.fields[event.target.name].type === 'number'
                                        ? parseFloat(event.target.value)
                                        : event.target.value
                    },
                    fields: {
                        ...this.state.data.fields,
                        [event.target.name]: {
                            ...this.state.data.fields[event.target.name],
                            error: undefined
                        }
                    }
                }
            }, this.props.onInputChange(event.target, this.state.data))
        } else {
            this.props.onInputChange(event.target, this.state.data)
        }
    }

    setOnliOneField(field) {
        Object.keys(field).map((campo) => {
            this.setState({
                data: {
                    ...this.state.data,
                    data: {
                        ...this.state.data.data,
                        [campo]: field[campo]
                    }
                }
            })
        })
    }

    open = (data) => {
        this.setState({ show: true, isLoading: false, data: data }, () => {
            document.body.classList.add('modal-open')
        })
    }

    close = () => {
        this.setState({ show: false }, () => {
            document.body.classList.remove('modal-open')
            this.setState(this.baseState)
            this.props.closingDialog()
            // success()
        })
    }

    save = (event) => {
        event.preventDefault()
        console.log('save()...');
        console.log(this.state);
        this.setState({ isLoading: true }, () => {
            console.log(this.state.data.data);
            fetch(this.props.methodput, {
                method: 'PUT',
                body: JSON.stringify({ data: this.state.data.data }),
                headers: new Headers({
                    'AuthorizationSa': store.get('data').token,
                    'email': store.get('data').usuario.email,
                    'pin': store.get('data').pin
                })
            }).then(res => {
                console.log('!save...')
                this.setState({ isLoading: false })
                return res.json()
            }).then(data => {
                if (data.hasOwnProperty("error")) {
                    Alert('danger', this.props.title, data.error)
                    this.errors(data.fields, data.error)
                } else {
                    Alert('success', this.props.title, data.message)
                    this.close()
                }
            }).catch(error => { console.error(error) })
        })
    }

    errors = (fields, description) => {

        fields.map((field) => {
            if (this.state.data.fields[field]) {
                this.setState({
                    data: {
                        ...this.state.data,
                        fields: {
                            ...this.state.data.fields,
                            [field]: {
                                ...this.state.data.fields[field],
                                error: description
                            }
                        }
                    }
                })
                return null
            } else {
                return null
            }
        })
    }

    delteItemTable = (name, table, row) => {
        console.log(name)
        console.log(table)
        let temp = table.filter(item => item !== row)
        console.log(temp)
        this.setOnliOneField({ [name]: temp })
    }

    render() {
        const { title, large, modal } = this.props
        const { show, data } = this.state

        return (
            <div>
                {(show && modal === true)
                    ? <div>
                        <div className={`modal fade ${show && "show"}`} tabIndex="-1" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
                            <div className={`modal-dialog ${large && "modal-lg"}`}>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title" id="exampleModalLabel">{title}</h6>
                                        <button type="button" className="close" onClick={this.close} aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body" >
                                        <FormE data={data} onSubmit={this.save} handleInputChange={this.handleInputChange}/>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={this.close}>Cerrar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                    : modal === false &&
                    <FormE
                        data={data}
                        onSubmit={this.save}
                        handleInputChange={this.handleInputChange}
                    />
                }
            </div>
        )
    }
}
export default Form

class FormE extends Component {
    constructor(props) {
        super(props)
        this.state = { ...props, isLoading: false }
    }
    render() {
        const { data } = this.state
        const rows = (data && data.hasOwnProperty('fields') ? data.fields['rows'] : 1)
        return (
            <form onSubmit={(e) => this.props.onSubmit(e, this.state)}>
                {data && Array.from(Array(rows), (e, i) => (
                    <div key={'row' + i} className="form-row ">
                        {Object.keys(data.fields).map((field, index) => (
                            data.fields[field].visible && (!data.fields[field].hasOwnProperty('row') || data.fields[field].row === (i + 1))
                                ? data.fields[field].type === 'text' || data.fields[field].type === 'email' || data.fields[field].type === 'password' || data.fields[field].type === 'number'
                                    ? <Input
                                        key={field}
                                        name={field}
                                        field={data.fields[field]}
                                        value={data.data[field]}
                                        readOnly={!data.fields[field].editable || this.state.isLoading}
                                        handleInputChange={this.props.handleInputChange} />
                                    : data.fields[field].type === 'select'
                                        ? <Select
                                            key={field}
                                            data={data.data[field]}
                                            field={data.fields[field]}
                                            value={data.data[data.fields[field].parent]}
                                            readOnly={!data.fields[field].editable || this.state.isLoading}
                                            handleInputChange={this.props.handleInputChange} />
                                        : data.fields[field].type === 'checkbox'
                                            ? <CheckBox
                                                key={field}
                                                name={field}
                                                field={data.fields[field]}
                                                value={data.data[field]}
                                                readOnly={!data.fields[field].editable || this.state.isLoading}
                                                handleInputChange={this.props.handleInputChange} />
                                            : data.fields[field].type === 'radio'
                                                ? <Radio
                                                    key={field}
                                                    data={data.data[field]}
                                                    name={field}
                                                    field={data.fields[field]}
                                                    value={data.data[data.fields[field].parent]}
                                                    readOnly={!data.fields[field].editable || this.state.isLoading}
                                                    handleInputChange={this.props.handleInputChange} />
                                                : data.fields[field].type === 'selectmultiple'
                                                    ? <SelectMutiple
                                                        key={field}
                                                        data={data.data[field]}
                                                        field={data.fields[field]}
                                                        value={data.data[data.fields[field].parent]}
                                                        readOnly={!data.fields[field].editable || this.state.isLoading}
                                                        handleInputChange={this.props.handleInputChange} />
                                                    : data.fields[field].type === 'button'
                                                        ? <Button
                                                            key={field}
                                                            name={field}
                                                            field={data.fields[field]}
                                                            value={data.data[field]}
                                                            readOnly={!data.fields[field].editable || this.state.isLoading}
                                                            handleInputChange={this.props.handleInputChange} />
                                                        : data.fields[field].type === 'table'
                                                            ? <Table
                                                                key={field}
                                                                name={field}
                                                                field={data.fields[field]}
                                                                value={data.data[field]}
                                                                readOnly={!data.fields[field].editable || this.state.isLoading}
                                                                handleInputChange={this.props.handleInputChange}
                                                                delteItemTable={this.delteItemTable} />
                                                            : null
                                : null
                        ))}
                    </div>
                ))}
                <div className="form-row ">
                    <div className="form-group form-group-sm col-auto">
                        <button type="submit" className="btn btn-primary" >Guardar cambios</button>
                    </div>
                </div>
            </form>
        )
    }
}

class Input extends Component {

    render() {
        const { name, field, value, readOnly, handleInputChange } = this.props
        return (
            <div className="form-group form-group-sm col-auto">
                <label htmlFor={`id_${name}`}><small>{field.label}</small></label>
                <input
                    id={`id_${name}`}
                    type={field.type}
                    className={`form-control form-control-sm ${(field.hasOwnProperty('error') && field.error) && "is-invalid"}`}
                    name={name}
                    defaultValue={value == null ? undefined : value}
                    readOnly={readOnly}
                    onChange={handleInputChange}
                    required={field.required}
                    maxLength={field.maxlength}
                    autoComplete="off" />
                {(field.hasOwnProperty('error') && field.error) &&
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        {field.error}
                    </div>}
            </div>
        )
    }
}

class Select extends Component {
    componentDidMount() {
        console.log(this)
    }
    render() {
        const { data, field, value, readOnly, handleInputChange } = this.props

        return (
            <div className="form-group form-group-sm col-auto">
                <label htmlFor={`id_${field.parent}`}><small>{field.label}</small></label>
                <select
                    id={`id_${field.parent}`}
                    className="form-control form-control-sm"
                    name={field.parent || null}
                    defaultValue={value == null ? undefined : value}
                    readOnly={readOnly}
                    required={field.required}
                    onChange={handleInputChange}>
                    {value === null || value === undefined || value
                        ? <option value="" >...</option>
                        : null
                    }
                    {data && data.hasOwnProperty('list') && data.list !== null &&
                        data.list.map(d => (
                            <option key={d.f1} value={d.f1} title={d.f2}>
                                {d.f2.length > 30 ? d.f2.substring(0, 29) + '...' : d.f2}
                            </option>
                        ))
                    }
                </select>
            </div>
        )
    }
}

class SelectMutiple extends Component {
    render() {
        const { data, field, value, readOnly, handleInputChange } = this.props
        let lb = [];

        if (value != null && data != null && data.hasOwnProperty("list") && data.list !== null) {
            value.map(v => {
                data.list.map(list => {
                    if (v === list.f1.toString()) {
                        lb.push(list.f2)
                    }
                })
            })
        }
        console.log(lb)
        return (
            <div className="form-group form-group-sm col-auto">
                <label htmlFor={`id_${field.parent}`}><small>{field.label} (<strong>{lb.join(', ')}</strong>)</small></label>
                <select
                    id={`id_${field.parent}`}
                    className="form-control form-control-sm"
                    name={field.parent}
                    value={value}
                    readOnly={readOnly}
                    required={field.required}
                    onChange={handleInputChange} multiple>
                    {data != null && data.hasOwnProperty("list") && data.list !== null &&
                        data.list.map(d => (
                            <option key={d.f1} value={d.f1} >{d.f2}</option>
                        ))
                    }
                </select>
            </div>
        )
    }
}


class CheckBox extends Component {
    render() {
        const { name, field, value, readOnly, handleInputChange } = this.props
        return (
            <div className="form-group form-check">
                <input
                    id={`id_${name}`}
                    type={field.type}
                    className="form-check-input"
                    name={name}
                    checked={value ? value : false}
                    readOnly={readOnly}
                    //required={field.required}
                    onChange={handleInputChange}
                    autoComplete="off" />
                <label className="form-check-label" htmlFor={`id_${name}`}><small>{field.label}</small></label>
            </div>
        )
    }
}

class Radio extends Component {
    render() {
        const { data, name, field, value, readOnly, handleInputChange } = this.props

        return (
            <>
                <div className="form-group ">
                    <label className="form-check-label" htmlFor={`id_${name}`}><small>{field.label}</small></label>
                    {data != null && data.hasOwnProperty("list") && data.list !== null &&
                        data.list.map(d => (
                            <div key={d.f1} className="form-check">
                                <input
                                    id={`id_radio_${name}_${d.f1}`}
                                    type={field.type}
                                    className="form-check-input"
                                    name={field.parent}
                                    defaultChecked={d.f1 === value}
                                    readOnly={readOnly}
                                    value={d.f1}
                                    //required={field.required}
                                    onChange={handleInputChange}
                                    autoComplete="off" />
                                <label className="form-check-label" htmlFor={`id_${d.f1}`}>{d.f2}</label>
                            </div>
                        ))}
                </div>
            </>
        )
    }
}

class Button extends Component {
    render() {
        const { name, field, handleInputChange } = this.props
        return (
            <div className="form-group form-group-sm col-auto">
                <label htmlFor={`id_${name}`}>&nbsp;</label><br />
                <button id={`id_${name}`} name={name} parent={field.parent || null} type="button" className="btn btn-secondary btn-sm" onClick={handleInputChange}>{field.label}</button>
            </div>
        )
    }
}

class Table extends Component {
    render() {
        const { name, field, handleInputChange, value } = this.props
        return (
            <table style={{ "fontSize": "11px" }} className="table table-sm">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        {Object.keys(field.columns).map((key, index) => (
                            <th key={key} scope="col">{field.columns[key].label}</th>
                        ))}
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {value && value.map((data, index) => (
                        <tr key={index}>
                            <th scope="row">{index}</th>
                            {Object.keys(field.columns).map((col, icol) => (
                                <td key={icol}>{data[col]}</td>
                            ))}
                            <td style={{ cursor: "pointer" }}>
                                <FontAwesomeIcon icon={"trash"} color={"red"} size="sm"
                                    onClick={(e) => this.props.delteItemTable(name, value, data)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }
}
