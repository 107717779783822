import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import store from 'store'

class Inicio extends Component {
    render() {
        return <div></div>
        // if (!store.get('loggedIn')) {
        //     return <Redirect to='/login' />
        // } else {
        //     return <Redirect to='/dashboard' />
        // }
    }

}
export default Inicio