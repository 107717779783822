import React, { Component } from 'react'
import { properties as prop } from '../Properties.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faFileCode, faFilePdf } from '@fortawesome/free-solid-svg-icons'

class Inicio extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mensaje: '',
            acuse: {},
            botonInactivo: false,
            botonPdfInactivo: false,
            botonXmlInactivo: false,
            acuseId: props.match.params.acuseId,
            desicion: 'a',
            observaciones: '',
            errorObesrva: '',
            seRechaza: false,
            concepto: ''
        };
    }

    componentDidMount() {
        const { acuseId } = this.state

        fetch(`${prop.server}/acusebyid/${acuseId}`)
            .then(res => res.json())
            .then(respuesta => {
                console.log(respuesta)
                if (respuesta.data.numero_documento) {
                    this.setState({ acuse: respuesta.data })
                    if (this.state.acuse.estado_acuse !== 'p') {
                        this.setState({ mensaje: 'Esta acción ya fue realizada' })
                    }
                    if (this.state.acuse.estado_pdf !== 'p') {
                        this.setState({ botonPdfInactivo: true })
                    }
                    if (this.state.acuse.estado_xml !== 'p') {
                        this.setState({ botonXmlInactivo: true })
                    }
                } else {
                    this.setState({ mensaje: respuesta.data.mensaje })
                }
            })
    }

    seleccionAccion = (e) => {
        let v = e.target.value
        console.log(v)
        this.setState({ desicion: v, concepto: '' })
        if (v) {
            this.setState({ botonInactivo: v !== 'a', seRechaza: v === 'r' })
        } else {
            this.setState({ botonInactivo: true, seRechaza: false })
        }
    }

    seleccionConcepto = (e) => {
        let v = e.target.value
        console.log(v)
        this.setState({ concepto: v })
        if (v) {
            this.setState({ botonInactivo: false })
        } else {
            this.setState({ botonInactivo: true })
        }
    }

    enviarAcuse = (e) => {
        console.log('enviar acuse')
        console.log(this.state)

        fetch(`${prop.server}acusar_factura`, {
            method: 'POST',
            body: JSON.stringify(this.state),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => console.error('Error:', error))
            .then(response => {
                console.log('Success:', response)
                console.log('Status:', response.status)
                if (response.status === 400) {
                    this.setState({ errorObesrva: response.data })
                } else {
                    this.setState({ acuse: { estado_acuse: 'x' } })
                    this.setState({ mensaje: response.data.mensaje })
                }
            });
    }

    verPdf = (e) => {
        const { acuseId } = this.state
        // window.open(`${prop.server}mostrar_pdf/${acuseId}`)
        let a = document.createElement('a')
        a.href = `${prop.server}mostrar_pdf/${acuseId}`
        a.click()
        this.setState({ botonPdfInactivo: true })
    }

    verXml = (e) => {
        const { acuseId } = this.state
        //window.open(`${prop.server}mostrar_xml/${acuseId}`)
        let a = document.createElement('a')
        a.href = `${prop.server}mostrar_xml/${acuseId}`
        a.click()
        this.setState({ botonXmlInactivo: true })
    }

    render() {

        return (
            <div className="app flex-row align-items-center">
                <div className="container" id="acuseReciboContainer">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="card-group mb-0">
                                <div className="card p-5">
                                    <div className="card-block text-center">
                                        <h1>Acuse de Recibo</h1>
                                        {this.state.acuse
                                            ?
                                            <div>
                                                {this.state.acuse.estado_acuse === 'p'
                                                    ?
                                                    <div>
                                                        <p className="text-muted">
                                                            Facturación Electrónica, documento <strong>{this.state.acuse.tipo_documento} {this.state.acuse.numero_documento} del {this.state.acuse.fecha_documento}</strong>
                                                        </p>
                                                        <div className=" mb-3">
                                                            <button
                                                                type="button"
                                                                className="btn btn-link"
                                                                onClick={this.verPdf}
                                                                disabled={this.state.botonPdfInactivo}>
                                                                <FontAwesomeIcon icon={faFilePdf} color="red" />&nbsp;
                                                                <u>{this.state.acuse.numero_documento}.pdf</u>
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className="btn btn-link"
                                                                onClick={this.verXml}
                                                                disabled={this.state.botonXmlInactivo}>
                                                                <FontAwesomeIcon icon={faFileCode} color="orange" />&nbsp;
                                                                <u>{this.state.acuse.numero_documento}.xml</u>
                                                            </button>
                                                        </div>

                                                        <div className="form-group text-left">
                                                            <label>Seleccione una acción</label>
                                                            <select
                                                                placeholder="Seleccionar..."
                                                                className="form-control"
                                                                value={this.state.desicion}
                                                                onChange={this.seleccionAccion}>
                                                                <option value="">Seleccionar...</option>
                                                                <option value="a">Aceptada</option>
                                                                <option value="r">Rechazada</option>
                                                            </select>
                                                        </div>
                                                        {this.state.seRechaza
                                                            ?
                                                            <div className="form-group text-left">
                                                                <label>Seleccione un concepto</label>
                                                                <select
                                                                    placeholder="Seleccionar..."
                                                                    className="form-control"
                                                                    value={this.state.concepto}
                                                                    onChange={this.seleccionConcepto}
                                                                    disabled={!this.state.seRechaza}>
                                                                    <option value="">Seleccionar...</option>
                                                                    <option value="01">Documento con inconsistencias</option>
                                                                    <option value="02">Mercancía no entregada totalmente</option>
                                                                    <option value="03">Mercancía no entregada parcialmente</option>
                                                                    <option value="04">Servicio no prestado</option>
                                                                </select>
                                                            </div>
                                                            : null}

                                                        {this.state.errorObesrva
                                                            ? <p className="text-danger">
                                                                {this.state.errorObesrva}
                                                            </p>
                                                            : ''
                                                        }
                                                        <div className="input-group mb-4">
                                                            <textarea
                                                                className="form-control"
                                                                value={this.state.observaciones}
                                                                onChange={e => this.setState({ observaciones: e.target.value })}
                                                                rows="4" cols="50" placeholder="Observaciones, maximo 300 caracteres">
                                                            </textarea>
                                                        </div>
                                                        <div className="mb-4">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={this.enviarAcuse}
                                                                disabled={this.state.botonInactivo}>Acusar <FontAwesomeIcon icon={faPaperPlane} /></button>
                                                        </div>
                                                        <div className="row text-left">
                                                            <div className="col-sm-12 col-md-12 col-lg-12 text-muted" style={{ fontSize: '11px' }}>
                                                                <p className="h6">
                                                                    * Al seleccionar <strong>Aceptar</strong> se entiende que se recibió el documento, en ningun momento implica la aceptacion del contenido, valores y/o cantidades expresadas en él.
                                                                </p>
                                                                <p className="h6">
                                                                    * Al seleccionar <strong>Rechazar</strong> se entiende que se recibió el documento, pero dicho documento no es legible, está mal formado o el adquiriente no es el correcto.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : <h2>{this.state.mensaje}</h2>
                                                }
                                            </div>
                                            :
                                            <h2>{this.state.mensaje}</h2>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default Inicio
