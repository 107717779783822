import React, { Component } from 'react'
import { properties as serverprop } from '../Properties.js'
import store from 'store'

class Pin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            values: {                
                email: store.get('data') && store.get('data').usuario.email,
                token: store.get('data') && store.get('data').token,
                empresa_id: store.get('data') && store.get('data').empresa.empresa_id,
                origin: store.get('data').origin,
                pin: ""
            },
            message: "",
            isSubmitting: false,
            isError: false,
            isDisabled: false
        };

    }
    handleInputChange = (event) => {
        this.setState({
            values: { ...this.state.values, [event.target.name]: event.target.value }
        });
    }

    handleStoreSession = (data) => {
        this.setState({ message: data.success })
        store.set('loggedIn', true);
        store.set('data', { empresa: data.empresa, usuario: data.usuario, token: data.token, empresa_id: this.state.values.empresa_id, origin: this.state.values.origin, pin: this.state.values.pin});
        this.props.history.push(`/dashboardsa`)
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ isSubmitting: true, isDisabled: true });

        fetch(`${serverprop.server}sapin`, {
            method: 'POST',
            body: JSON.stringify(this.state.values),
            headers: {
                'Content-Type': 'application/json',
                'AuthorizationSa': this.state.values.token,
                'email': this.state.values.email,
                'pin': this.state.values.pin,
            }
        }).then(res => {
            if (res.status === 404) {
                this.setState({ isSubmitting: false, isError: true, message: res.statusText });
            } else if (res.status === 401) {
                store.remove('loggedIn');
                store.remove('data');
                this.props.history.push(this.state.values.origin)
            } else {
                this.setState({ isSubmitting: false });
            }
            this.setState({ isDisabled: false });
            return res.json();
        }).then(data => {
            console.log('entre al data')
            console.log(data)
            this.setState({ isDisabled: false });
            !data.hasOwnProperty("error")
                ? this.handleStoreSession(data)
                : this.setState({ message: data.error, isError: true });
        }).catch(error => {
            this.setState({ isSubmitting: false, isDisabled: false, message: 'Se produjo un error', isError: true })
        });
    }

    render() {
        if (!this.state.values.email || !this.state.values.token) {
            this.props.history.push(this.state.values.origin)
        }else if(store.get('loggedIn') && store.get('data') && store.get('data').usuario){
            this.props.history.push(`/dashboardsa`)
        }
        const form_signin = {
            "width": "100%",
            "maxWidth": "330px",
            "paddingTop": "50px",
            "paddingBottom": "50px",
            "margin": "auto",
        }
        return (
            <div className="container">
                <div className="jumbotron mt-3">
                    <form style={form_signin} className="form-signin" onSubmit={this.handleSubmit}>
                        <h1 className="h3 mb-3 font-weight-normal">Se envió el PIN a su correo electrónico</h1>

                        <label htmlFor="inputPin" className="sr-only">PIN</label>
                        <input type="number" id="inputPin" className="form-control" placeholder="PIN" autoComplete="off" required name="pin" value={this.state.values.pin} onChange={this.handleInputChange} />

                        <hr className="my-4" />
                        <div className={`message ${this.state.isError && "error"}`}>
                            {this.state.isSubmitting ? "Submitting..." : this.state.message}
                        </div>
                        <button className="btn btn-lg btn-primary btn-block" type="submit" disabled={this.state.isDisabled}>Validar</button>
                    </form>
                </div>
            </div>

        );
    }
}

export default Pin