import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { properties as serverprop } from '../Properties.js'
import styles from '../sb-admin-styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBars, faSearch, faTachometerAlt, faUsersCog, faUserTie, faUserTag, faIndustry, faFileInvoice, faFileAlt, faInfoCircle, faSortDown, faSortUp, faSort, faPen, faTrash, faBookOpen, faAddressBook, faIndent, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import store from 'store'

import packageJson from '../../package.json';

//General
import Home from './dashboardsa/Home'

//ClientAdmin
import GenerarDocumentos from './dashboardsa/GenerarDocumentos'
import ClientesEmpresa from './dashboardsa/ClientesEmpresa'
import ProductosEmpresa from './dashboardsa/ProductosEmpresa'

//SuperAdmin
import Empresas from './dashboardsa/Empresas'
import UsuariosEmpresa from './dashboardsa/UsuariosEmpresa'
import UsuariosNotificacion from './dashboardsa/UsuariosNotificacion'
import DocumentosEmpresa from './dashboardsa/DocumentosEmpresa'
import Usuarios from './dashboardsa/Usuarios'
//

import EmpresaResumen from './dashboardsa/EmpresaResumen'
import EmpresaResoluciones from './dashboardsa/ResolucionFacturacion'



library.add(faUser, faBars, faSearch, faTachometerAlt, faUsersCog, faUserTie, faUserTag, faIndustry, faFileInvoice, faFileAlt, faInfoCircle, faSortDown, faSortUp, faSort, faPen, faTrash, faBookOpen, faAddressBook, faIndent, faPlusCircle)

document.body.classList.add('sb-nav-fixed');
class DashboardSa extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navbarIsVisible: true,
            usuario: store.get('data') && store.get('data').usuario,
            empresa_id: store.get('data') && store.get('data').empresa_id,
            menu: store.get('data') && store.get('data').usuario.menu,
            pagina: Blanck
        }
    }
    componentDidMount() {
        console.log(process.env)
        console.log(this.state.menu)
        this.cargarPagina('home')
    }
    toggleNavBar = () => {
        // event.preventDefault();
        this.setState({ navbarIsVisible: !this.state.navbarIsVisible }, function () {
            if (!this.state.navbarIsVisible) {
                document.body.classList.add('sb-sidenav-toggled');
            } else {
                document.body.classList.remove('sb-sidenav-toggled');
            }
        })

    }
    handleLogout = (event) => {
        event.preventDefault();
        fetch(`${serverprop.server}dashboardsa/logout`, {
            method: 'POST',
            body: JSON.stringify({ token: store.get('data').token, email: store.get('data').usuario.email, empresa_id: store.get('data').empresa_id }),
            headers: new Headers({
                'AuthorizationSa': store.get('data').token,
                'email': store.get('data').usuario.email,
                'pin': store.get('data').pin,
            })
        }).then(res => {
            return res.json()
        }).then(data => {
            let origin = store.get('data').origin
            store.remove('loggedIn');
            store.remove('data');
            this.props.history.push(origin)
        }).catch(error => { console.error(error) })

    }
    handleMenu = (event) => {
        event.preventDefault()
        this.cargarPagina(event.target.id)
    }
    cargarPagina = (id) => {
        console.log(id)
        switch (id) {
            case 'home':
                this.setState({ pagina: Home })
                break
            //ClienteAdmin
            case 'generar_documentos':
                this.setState({ pagina: GenerarDocumentos })
                this.toggleNavBar()
                break
            case 'consultar_documentos':
                this.setState({ pagina: DocumentosEmpresa })
                break
            case 'clientes_empresa':
                this.setState({ pagina: ClientesEmpresa })
                break
            case 'productos_empresa':
                this.setState({ pagina: ProductosEmpresa })
                break
            //SuperAdmin
            case 'empresas':
                this.setState({ pagina: Empresas })
                break
            case 'usuarios_empresa':
                this.setState({ pagina: UsuariosEmpresa })
                break
            case 'usuarios_notificacion':
                this.setState({ pagina: UsuariosNotificacion })
                break
            case 'documentos_empresas':
                this.setState({ pagina: DocumentosEmpresa })
                break
            case 'usuarios':
                this.setState({ pagina: Usuarios })
                break
            case 'empresa_resumen':
                this.setState({ pagina: EmpresaResumen })
                break
            case 'empresa_resoluciones_factura':
                this.setState({ pagina: EmpresaResoluciones })
                break
            default:
        }
        var mit = document.getElementsByName("mit")
        for (var i = 0; i < mit.length; i++) {
            mit[i].classList.remove('active')
            if (mit[i].id === id) {
                mit[i].classList.add('active')
            }
        }
        if (window.innerWidth < 992) {
            this.toggleNavBar()
        }
    }
    render() {
        if (!store.get('loggedIn') || !this.state.usuario) {
            return <Redirect to={store.get('data').origin} />
        }
        return (
            <div>
                <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
                    <a className="navbar-brand" href="/#/dashboardsa">JAiVAna S.A.S.</a>
                    <button className="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle" onClick={this.toggleNavBar}><FontAwesomeIcon icon={faBars} /></button>

                    <form className="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
                        <div className="input-group">
                            <input className="form-control" type="text" placeholder="Buscar..." aria-label="Search" aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <button className="btn btn-primary" type="button"><FontAwesomeIcon icon={"search"} /></button>
                            </div>
                        </div>
                    </form>

                    <ul className="navbar-nav ml-auto ml-md-0">
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" id="userDropdown" href="/#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><FontAwesomeIcon icon={faUser} /></a>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                                {/* <a className="dropdown-item" href="/#">Configuración</a>
                                <div className="dropdown-divider"></div> */}
                                <a className="dropdown-item" href="/#" onClick={this.handleLogout}>Salir</a>
                            </div>
                        </li>
                    </ul>
                </nav>
                <div id="layoutSidenav">
                    <div id="layoutSidenav_nav">
                        <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                            <div className="sb-sidenav-menu">
                                <div className="nav">
                                    {
                                        this.state.menu.map((m, index) =>
                                            <Fragment key={index}>
                                                <div className="sb-sidenav-menu-heading">{m.title}</div>
                                                {
                                                    m.submenu.map((s, subindex) =>
                                                        <a key={s.id} className="nav-link" href="/#" name="mit" id={s.id} onClick={(e) => this.handleMenu(e)}>
                                                            <div className="sb-nav-link-icon"><FontAwesomeIcon icon={s.icon} /></div>
                                                            {s.title}
                                                        </a>
                                                    )
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {/* <div className="sb-sidenav-menu-heading">Core</div>
                                    <a className="nav-link active" href="/#" name="mit" id="home" onClick={(e) => this.cargarPagina(e)}>
                                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faTachometerAlt} /></div>
                                        Inicio
                                    </a>
                                    <a className="nav-link" href="/#" name="mit" id="generar_documentos" onClick={(e) => this.cargarPagina(e)}>
                                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faFileAlt} /></div>
                                        Generar Documentos
                                    </a>

                                    <div className="sb-sidenav-menu-heading">Administrar Empresas</div>
                                    <a className="nav-link" href="/#" name="mit" id="empresas" onClick={(e) => this.cargarPagina(e)}>
                                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faIndustry} /></div>
                                        Empresas
                                    </a>
                                    <a className="nav-link" href="/#" name="mit" id="usuarios_empresa" onClick={(e) => this.cargarPagina(e)}>
                                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faUserTie} /></div>
                                        Usuarios
                                    </a>
                                    <a className="nav-link" href="/#" name="mit" id="usuarios_notificacion" onClick={(e) => this.cargarPagina(e)}>
                                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faUserTag} /></div>
                                        Notificacion
                                    </a>
                                    <a className="nav-link" href="/#" name="mit" id="documentos_empresas" onClick={(e) => this.cargarPagina(e)}>
                                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faFileInvoice} /></div>
                                        Documentos
                                    </a>

                                    <div className="sb-sidenav-menu-heading">Configuración</div>
                                    <a className="nav-link" href="/#" name="mit" id="usuarios" onClick={(e) => this.cargarPagina(e)}>
                                        <div className="sb-nav-link-icon"><FontAwesomeIcon icon={faUsersCog} /></div>
                                        Usuarios
                                    </a> */}
                                </div>
                            </div>
                            <div className="sb-sidenav-footer">
                                <div className="small">Conectado como:</div>
                                {this.state.usuario.nombre}
                            </div>
                        </nav>
                    </div>
                    <div id="layoutSidenav_content">
                        <main>
                            {<this.state.pagina server={serverprop.server} />}
                        </main>
                        <footer className="py-4 bg-light mt-auto">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between small">
                                    <div className="text-muted">Copyright &copy; JAiVAna S.A.S. 2020</div>
                                    <div>V.{packageJson.version}</div>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        )
    }
}
export default DashboardSa

function Blanck(props) {
    return <div></div>
}