import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import store from 'store'
import Tabla from '../../components/Tabla'
import Formulario from '../../components/Formulario'
import { Alert } from '../../components/Alerta'

class Usuarios extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columnastabla: [
                { name: "id", sortable: false, visible: false },
                { name: "nombre", text: "Nombre Usuario", sortable: true, visible: true },
                { name: "email", text: "Email", sortable: true, visible: true },
                { name: "tipo", text: "Rol", sortable: true, visible: true },
                { name: "estado_texto", text: "Estado", sortable: true, visible: true },
                {
                    name: "action", text: "", type: "action", visible: true, actions: [
                        { name: "modificar", icon: "pen", action: this.editarUsuario, color: "blue" },
                        { name: "eliminar", icon: "trash", action: this.eliminarUsuario, color: "red" }
                    ]
                }
            ],
            itemstabla: [],
            configTabla: {
                full_count: 0,
                page: 0,
                orderby: "id",
                sort_order: "desc",
                limit: 10,
                totalPages: 0,
                isLoading: false
            }
        }
    }
    componentDidMount() {
        this.eventoUsuarios()
    }
    dialogoUsuario = (id) => {
        fetch(`${this.props.server}dashboardsa/usuario_editar?id=${id}`, {
            method: 'GET',
            headers: new Headers({
                'AuthorizationSa': store.get('data').token,
                'email': store.get('data').usuario.email,
                'pin': store.get('data').pin,
            })
        }).then(res => {
            return res.json()
        }).then(data => {
            console.log(data)
            this.dialogo.open(data)
        }).catch(error => {
            console.error(error)
        })

    }
    agregarUsuario = () => {
        this.setState({
            tituloDialogoUsuario: "Creando Usuario",
            metodoDialogoUsuario: `${this.props.server}dashboardsa/usuario_agregar`
        }, this.dialogoUsuario(0))

    }
    editarUsuario = (usuario) => {
        this.setState({
            tituloDialogoUsuario: "Editando Usuario",
            metodoDialogoUsuario: `${this.props.server}dashboardsa/usuario_editar`
        }, this.dialogoUsuario(usuario.id))
    }
    onInputChangeDialog = () => { }
    eliminarUsuario = (usuario) => {
        fetch(`${this.props.server}dashboardsa/usuarios_elimiar`, {
            method: 'POST',
            body: JSON.stringify({ usuario: usuario }),
            headers: new Headers({
                'AuthorizationSa': store.get('data').token,
                'email': store.get('data').usuario.email,
                'pin': store.get('data').pin,
            })
        }).then(res => { return res.json() })
            .then(data => {
                this.eventoUsuarios()
                data.hasOwnProperty("error")
                    ? Alert('danger', this.props.title, data.error)
                    : Alert('success', this.props.title, data.message)
            })
            .catch(error => { console.error(error) })
    }
    cerrandoDialogoUsuario = () => {
        this.eventoUsuarios()
    }

    eventoUsuarios = () => {
        var datos = { config: this.state.configTabla }
        this.getUsuarios(datos)
    }
    getUsuarios = (datos) => {
        console.log(datos)
        fetch(`${this.props.server}dashboardsa/usuarios?limit=${datos.config.limit}&page=${datos.config.page}&orderby=${datos.config.orderby}&sort_order=${datos.config.sort_order}`, {
            method: 'GET',
            headers: new Headers({
                'AuthorizationSa': store.get('data').token,
                'email': store.get('data').usuario.email,
                'pin': store.get('data').pin,
            })
        }).then(res => {
            return res.json()
        }).then(data => {
            console.log(data)
            const { usuarios, limit, page, full_count, orderby, sort_order } = data
            const totalPages = Math.ceil(full_count / limit)
            this.setState({
                itemstabla: usuarios,
                configTabla: {
                    full_count: full_count,
                    page: page,
                    orderby: orderby,
                    sort_order: sort_order,
                    limit: limit,
                    totalPages: totalPages,
                    isLoading: false
                }
            })
        }).catch(error => { console.error(error) })
    }

    render() {
        return (
            <div className="container-fluid" >
                <h1 className="mt-4">Usuarios</h1>
                <ol className="breadcrumb mb-2">
                    <li className="breadcrumb-item active">Lista de usuarios del sistema</li>
                    <div className="navbar-nav ml-auto m1-md-0 ">
                        <button type="button" className="btn btn-primary btn-sm" onClick={this.agregarUsuario}>
                            Agregar Usuario&nbsp;
                            <FontAwesomeIcon icon={faPlusCircle} size="sm" />
                        </button>
                    </div>
                </ol>
                <div className="col-xl">
                    <Tabla
                        config={this.state.configTabla}
                        columnas={this.state.columnastabla}
                        documentos={this.state.itemstabla}
                        traerDatos={this.getUsuarios} />
                </div>
                <Formulario
                    title={this.state.tituloDialogoUsuario}
                    ref={element => { this.dialogo = element }}
                    methodput={this.state.metodoDialogoUsuario}
                    closingDialog={this.cerrandoDialogoUsuario}
                    onInputChange={this.onInputChangeDialog}
                    modal={true}
                     />
            </div>
        )
    }

}
export default Usuarios
