import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Documentos from './Documentos'
import styles from '../simple-sidebar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen, faPowerOff, faUser } from '@fortawesome/free-solid-svg-icons'
import store from 'store'

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            navbarIsVisible: true, 
            empresa: store.get('data') && store.get('data').empresa, 
            usuario: store.get('data') && store.get('data').usuario 
        }

    }
    toggleNavBar = (event) => {
        event.preventDefault();
        this.setState({ navbarIsVisible: !this.state.navbarIsVisible })
    }
    handleLogout = (event) => {
        event.preventDefault();
        store.remove('loggedIn');
        store.remove('data');
        this.props.history.push(`/login`)
    }
    render() {
        if (!store.get('loggedIn')) {
            return <Redirect to='/login' />
        }
        return (
            <div className={`d-flex ${this.state.navbarIsVisible ? "" : "toggled"}`} id="wrapper">
                <div className="bg-light border-right" id="sidebar-wrapper">
                    <div className="sidebar-heading text-center">
                        <img style={{ width: "130px" }} className="mx-auto" src={process.env.PUBLIC_URL + '/logo_jaivana.png'} />
                        <p><small>Facturación Electrónica</small></p>
                    </div>
                    <div className="list-group list-group-flush">
                        <a href="/" className="list-group-item list-group-item-action bg-light">
                            <FontAwesomeIcon icon={faFolderOpen} size="lg" />&nbsp;Documentos
                        </a>
                        <a href="/" className="list-group-item list-group-item-action bg-light" onClick={this.handleLogout}>
                            <FontAwesomeIcon icon={faPowerOff} color="red" size="lg" />&nbsp;Salir
                        </a>
                    </div>
                </div>
                <div id="page-content-wrapper">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
                        <button onClick={this.toggleNavBar} className="navbar-brand btn-sm btn" type="button" >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <label className="navbar-brand mr-auto">{this.state.empresa.nombre}</label>
                        <label className="navbar-brand"><FontAwesomeIcon icon={faUser} color="#495057" />&nbsp;{this.state.usuario.nombre}</label>
                    </nav>
                    <div className="container-fluid">
                        <Documentos />
                    </div>
                </div>
            </div>
        )
    }
}
export default Dashboard