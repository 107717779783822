import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import store from 'store'
import Tabla from '../../components/Tabla'
import Formulario from '../../components/Formulario'

class ClientesEmpresa extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nit_cliente: "",
            nombre_cliente: "",
            columnastabla: [
                { name: "id", sortable: false, visible: false },
                { name: "identificacion", text: "Identificacion", sortable: true, visible: true },
                { name: "razon_social", text: "Razon Social", sortable: true, visible: true },
                { name: "municipio", text: "Municipio", sortable: true, visible: true },
                {
                    name: "action", text: "", type: "action", visible: true, actions: [
                        { name: "modificar", icon: "pen", action: this.editarCliente, color: "blue" }
                    ]
                }
            ],
            itemstabla: [],
            configTabla: {
                full_count: 0,
                page: 0,
                orderby: "id",
                sort_order: "desc",
                limit: 10,
                totalPages: 0,
                isLoading: false
            }
        }
    }
    componentDidMount() {
    }
    handleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    sumitFormConsulta = (event) => {
        event.preventDefault();
        this.setState({ configTabla: { ...this.state.configTabla, page: 0 } }, () => {
            var datos = { config: this.state.configTabla }
            this.getClientes(datos)
        })
    }
    agregarCliente = () => {
        this.setState({
            tituloDialogoClientes: "Creando Cliente",
            metodoDialogoCliente: `${this.props.server}dashboardsa/empresas_clientes_agregar`
        }, this.dialogoCliente(0))
    }
    editarCliente = (data) => {
        this.setState({
            tituloDialogoClientes: "Editando Cliente",
            metodoDialogoCliente: `${this.props.server}dashboardsa/empresas_clientes_editar`
        }, this.dialogoCliente(data.id))
    }
    dialogoCliente = (id) => {
        fetch(`${this.props.server}dashboardsa/empresas_clientes_editar?id=${id}`, {
            method: 'GET',
            headers: new Headers({
                'AuthorizationSa': store.get('data').token,
                'email': store.get('data').usuario.email,
                'pin': store.get('data').pin,
            })
        }).then(res => { return res.json() })
            .then(data => {
                this.dialogo.open(data)
            }).catch(error => { console.error(error) })
    }
    onInputChangeDialog = (target) => {
        //console.log(target.name + " " + target.value)
        if (target.name === 'departamento_id') {
            fetch(`${this.props.server}dashboardsa/empresas_geo_departamento?id=${target.value}`, {
                method: 'GET',
                headers: new Headers({
                    'AuthorizationSa': store.get('data').token,
                    'email': store.get('data').usuario.email,
                    'pin': store.get('data').pin,
                })
            }).then(res => {
                return res.json()
            }).then(data => {
                this.dialogo.setOnliOneField(data.data)
            }).catch(error => { console.error(error) })
        }
    }
    getClientes = (datosFiltro) => {
        this.setState({ isLoading: true, configTabla: { ...this.state.configTabla, isLoading: true } }, () => {
            fetch(`${this.props.server}dashboardsa/empresas_clientes?limit=${datosFiltro.config.limit}&page=${datosFiltro.config.page}&orderby=${datosFiltro.config.orderby}&sort_order=${datosFiltro.config.sort_order}&nit_cliente=${this.state.nit_cliente}&nombre_cliente=${this.state.nombre_cliente}`, {
                method: 'GET',
                headers: new Headers({
                    'AuthorizationSa': store.get('data').token,
                    'email': store.get('data').usuario.email,
                    'pin': store.get('data').pin,
                })
            }).then(res => {
                return res.json()
            }).then(data => {
                console.log(data)
                const { items, configTabla } = data
                this.setState({
                    isLoading: false,
                    itemstabla: items,
                    configTabla: configTabla
                })
            }).catch(error => { console.error(error) })
        })
    }
    cerrandoDialogoCliente = () => {
        console.log(this.state)
        var datos = { config: this.state.configTabla }
        this.getClientes(datos)
    }
    render() {
        const { isLoading } = this.state;
        return (
            <>
                <div className="container-fluid" >
                    <h1 className="mt-4">Clientes</h1>
                    <div className="ml-auto m1-md-0 ">
                        <button type="button" className="btn btn-primary btn-sm" onClick={this.agregarCliente}>
                            Agregar Cliente&nbsp;
                            <FontAwesomeIcon icon={"plus-circle"} size="sm" />
                        </button>
                    </div>
                    <div className="my-2 text-white form-busqueda">
                        <form className="p-2" onSubmit={this.sumitFormConsulta}>
                            <div className="form-row align-items-center">
                                <div className="form-group form-group-sm col-auto">
                                    <label htmlFor="inputIdentifica">Identificacion</label>
                                    <input type="text" className="form-control form-control-sm" id="inputIdentifica" placeholder="Identificacion" name="nit_cliente" value={this.state.nit_cliente} onChange={this.handleInputChange} />
                                </div>
                                <div className="form-group form-group-sm col-auto">
                                    <label htmlFor="inputRazon">Razon Social</label>
                                    <input type="text" className="form-control form-control-sm" id="inputRazon" placeholder="Razon Social" name="nombre_cliente" value={this.state.nombre_cliente} onChange={this.handleInputChange} />
                                </div>
                                <div className="form-group form-group-sm col-auto">
                                    <label htmlFor="button">&nbsp;</label>
                                    <button type="submit" className="btn btn-primary btn-sm form-control " id="button" disabled={isLoading}>
                                        {isLoading &&
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="false"></span>}
                                                &nbsp;Consultar
                                            </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-xl">
                        <Tabla
                            config={this.state.configTabla}
                            columnas={this.state.columnastabla}
                            documentos={this.state.itemstabla}
                            traerDatos={this.getClientes} />
                    </div>
                    <Formulario
                        modal={true}
                        title={this.state.tituloDialogoClientes}
                        large={true}
                        ref={element => { this.dialogo = element }}
                        methodput={this.state.metodoDialogoCliente}
                        closingDialog={this.cerrandoDialogoCliente}
                        onInputChange={this.onInputChangeDialog} />
                </div>
            </>
        )
    }
}
export default ClientesEmpresa