// import React from 'react'
import store from 'store'
// import { properties as serverprop } from '../Properties.js'

export default fetch = (originalFetch => {
    return (...args) => {
        const result = originalFetch.apply(this, args);
        result.then(res => {
            console.log('el estatus del res ' + res.status)
            if (res.status === 401) {
                let oigin=store.get('data').origin
                store.remove('loggedIn');
                store.remove('loggedIn');
                store.remove('data');
                window.location.href = `#/${oigin}`
            }
        })
        return result.then();
    };
})(fetch);
